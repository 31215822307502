import { Tabs as KTabs } from '@kobalte/core/tabs';
import { twMerge } from '@troon/tailwind-preset/merge';
import type { ComponentProps } from 'solid-js';

export const Tabs = KTabs;
export function TabList(props: ComponentProps<typeof KTabs.List>) {
	return (
		<KTabs.List
			{...props}
			class={twMerge(
				'relative flex flex-row justify-start gap-x-4 overflow-x-auto border-b border-b-neutral py-2 lg:gap-x-8',
				props.class,
			)}
		>
			{props.children}
			<Tabs.Indicator class="absolute bottom-0 border-b-2 border-b-brand transition-all duration-200" />
		</KTabs.List>
	);
}
export function Tab(props: ComponentProps<typeof KTabs.Trigger>) {
	return (
		<KTabs.Trigger
			{...props}
			class="relative cursor-pointer text-nowrap rounded-md bg-white px-2 py-1 font-semibold text-neutral-800 outline-none hover:bg-brand-100 focus-visible:ring-4 focus-visible:ring-brand-100 ui-selected:text-neutral-950"
		/>
	);
}
export function TabPanel(props: ComponentProps<typeof KTabs.Content>) {
	return (
		<KTabs.Content
			{...props}
			class="outline-none focus-visible:ring-2 focus-visible:ring-brand-100 focus-visible:ring-offset-2"
		/>
	);
}
